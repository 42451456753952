<template>
  <div class="checkout-page">
    <MetaManager
      title="Checkout"
      description="Complete your order by providing billing details and payment information."
    />
    <h1>Checkout</h1>
    <div class="checkout-container">
      <div class="billing-details">
        <h2>Billing Details</h2>
        <form @input="saveBillingDetails">
          <div class="form-row">
            <div class="form-group">
              <label for="firstName">*First Name</label>
              <input type="text" id="firstName" v-model="billingDetails.firstName" required />
            </div>
            <div class="form-group">
              <label for="lastName">*Last Name</label>
              <input type="text" id="lastName" v-model="billingDetails.lastName" required />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group">
              <label for="companyName">Company Name</label>
              <input type="text" id="companyName" v-model="billingDetails.companyName" />
            </div>
            <div class="form-group">
              <label for="address">*Address</label>
              <input type="text" id="address" v-model="billingDetails.address" required />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group">
              <label for="city">*Town/City</label>
              <input type="text" id="city" v-model="billingDetails.city" required />
            </div>
            <div class="form-group">
              <label for="zipCode">*Zip Code</label>
              <input type="text" id="zipCode" v-model="billingDetails.zipCode" required />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group">
              <label for="country">Country</label>
              <select id="country" v-model="billingDetails.country" required>
                <option v-for="country in countries" :key="country.code" :value="country.code">
                  {{ country.name }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="email">*Email Address</label>
              <input type="email" id="email" v-model="billingDetails.email" required />
            </div>
            <div class="form-group">
              <label for="phone">*Phone</label>
              <input type="tel" id="phone" v-model="billingDetails.phone" required />
            </div>
          </div>
        </form>
      </div>
      <div class="order-summary">
        <h2>Your Order</h2>
        <div v-for="(item, index) in checkoutCartItems" :key="index" class="order-item">
          <div class="item-details">
            <div class="item-name">{{ item.name }}</div>
            <div>QTY: {{ item.quantity }}</div>
            <button class="fine-print-link" @click="viewFinePrint(item)">View Fine Print</button>
          </div>
          <div class="item-price">${{ (calculateDiscountedPrice(item.originalPrice, item.discount) * item.quantity).toFixed(2) }}</div>
        </div>
        <div class="subtotal-cost">
          <span>Subtotal</span>
          <span>${{ subtotalCost }}</span>
        </div>
        <div class="coupon-code">
          <label for="couponCode">Coupon Code</label>
          <input type="text" v-model="couponCode" placeholder="Enter coupon code" />
          <button @click="applyCoupon" :disabled="isProcessing">Apply Coupon</button>
          <div v-if="couponMessage">{{ couponMessage }}</div>
        </div>
        
        <div class="discount-amount" v-if="discountAmount > 0">
          <span>Discount</span>
          <span>-${{ discountAmount }}</span>
        </div>
        <div class="total-cost">
          <span>Total Cost</span>
          <span>${{ totalCost }}</span>
        </div>
        <div class="terms-agreement">
          <input type="checkbox" id="terms-agreement-check" v-model="termsAgreed">
          <label for="terms-agreement-check">
            I have read the fine print of the gift cards I am purchasing and I agree to the 
            <a href="/terms-of-service" target="_blank">Terms of Service</a> and 
            <a href="/refund-policy" target="_blank">Refund Policy</a>.
            <strong>Click checkbox to continue checkout.</strong>
          </label>
        </div>
        
        <div v-if="termsAgreed" class="payment-section">
          <h2>Payment Details:</h2>
          <div class="payment-form">
            <form @submit.prevent="handlePayment">
              <div class="form-group">
                <label for="cardNumber">Card Number</label>
                <input type="text" id="cardNumber" v-model="cardDetails.cardNumber" @input="validateCardNumber" required />
              </div>
              <div class="form-row">
                <div class="form-group">
                  <label for="expiryDate">Expiry Date (MM/YY)</label>
                  <input type="text" id="expiryDate" v-model="cardDetails.expiryDate" @input="formatExpiryDate" required />
                </div>
                <div class="form-group">
                  <label for="cvv">CVV</label>
                  <input type="text" id="cvv" v-model="cardDetails.cvv" @input="validateCVV" required />
                </div>
              </div>
              <button type="submit" class="place-order-button" :disabled="isProcessing">
                Pay ${{ totalCost }}
              </button>
            </form>
          </div>
        </div>
        
        <div v-if="isProcessing" class="processing-spinner">
          <div class="spinner"></div>
          <span>Processing...</span>
        </div>
        <div v-if="transactionMessage" class="transaction-message">{{ transactionMessage }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import MetaManager from '../../components/MetaManager.vue';

export default {
  name: 'CheckoutPage',
  components: {
    MetaManager,
  },
  data() {
    return {
      messageTimeout: null,
      cardFields: null,
      transactionMessage: '',
      isProcessing: false,
      termsAgreed: false,
      billingDetails: {
        firstName: '',
        lastName: '',
        companyName: '',
        address: '',
        city: '',
        zipCode: '',
        country: 'US', // Default to United States
        email: '',
        phone: ''
      },
      taxRate: 0.065, // Default tax rate as fallback
      checkoutCartItems: JSON.parse(localStorage.getItem('checkoutCartItems')) || [],
      countries: [], // Initialize as empty array
      agreedToTerms: false,
      acceptInstance: null,
      authorizeApiLoginId: process.env.VUE_APP_AUTHORIZE_API_LOGIN_ID, // Add this line
      cardDetails: {
        cardNumber: '',
        expiryDate: '',
        cvv: ''
      },
    };
  },
  computed: {
    subtotalCost() {
    const subtotal = this.checkoutCartItems.reduce((total, item) => {
      const discountedPrice = this.calculateDiscountedPrice(item.originalPrice, item.discount, item.quantity);
      return total + discountedPrice * (item.quantity || 1);
    }, 0);
    return subtotal.toFixed(2);
  },
    totalCost() {
      try {
        const subtotal = parseFloat(this.subtotalCost) || 0;
        const tax = parseFloat(this.taxCost) || 0;
        const discount = parseFloat(this.discountAmount) || 0;
        const total = Math.max(0, subtotal + tax - discount);
        
        return Number.isFinite(total) ? total.toFixed(2) : '0.00';
      } catch (error) {
        console.error('Error calculating total:', error);
        return '0.00';
      }
    },
    isFormValid() {
      return (
        this.billingDetails.firstName &&
        this.billingDetails.lastName &&
        this.billingDetails.address &&
        this.billingDetails.city &&
        this.billingDetails.zipCode &&
        this.billingDetails.email &&
        this.billingDetails.phone
      );
    },
  },
  async mounted() {
    // Load Accept.js script
    const script = document.createElement('script');
    script.src = 'https://js.authorize.net/v1/Accept.js'; // Use live URL
    script.charset = 'utf-8';
    document.body.appendChild(script);
    this.triggerBeginCheckoutEvent();

    script.onload = () => {
      this.acceptInstance = window.Accept;
    };

    script.onerror = () => {
      console.error('Failed to load Accept.js');
    };

    this.loadBillingDetails();
    this.fetchCountries(); // Fetch countries when component is mounted
    window.addEventListener('cart-updated', this.updateCartItems);
  },
  beforeUnmount() {
    window.removeEventListener('cart-updated', this.updateCartItems);
      if (this.messageTimeout) {
        clearTimeout(this.messageTimeout);
      }
  },
  methods: {
    preventScroll(event) {
      event.preventDefault();
      event.stopPropagation();
    },
    clearTransactionMessage() {
      if (this.messageTimeout) {
        clearTimeout(this.messageTimeout);
      }
      
      // Set initial timeout for starting the fade
      this.messageTimeout = setTimeout(() => {
        // Add the fade-out class
        const messageEl = document.querySelector('.transaction-message');
        if (messageEl) {
          messageEl.classList.add('fade-out');
        }
        
        // Wait for fade animation to complete before removing the message
        setTimeout(() => {
          this.transactionMessage = '';
          // Remove the fade-out class after message is cleared
          if (messageEl) {
            messageEl.classList.remove('fade-out');
          }
        }, 500); // This should match the CSS transition duration
      }, 4500); // Show message for 4.5 seconds before starting fade
    },
    triggerBeginCheckoutEvent() {
      if (this.checkoutCartItems.length > 0) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'begin_checkout',
          ecommerce: {
            currency: 'USD',
            value: parseFloat(this.totalCost),
            items: this.checkoutCartItems.map(item => ({
              item_name: item.name,
              item_id: item.giftCardId,
              price: parseFloat(item.originalPrice),
              quantity: parseInt(item.quantity)
            }))
          }
        });
      }
    },
    async confirmOrder(orderId) {
      try {
        const userId = JSON.parse(localStorage.getItem('user'))?.id;
        if (!userId) {
          throw new Error('User not logged in');
        }

        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/payment/confirm-order`,
          {
            orderId,
            userId,
            items: this.checkoutCartItems.map(item => ({
              giftCardId: item.giftCardId,
              quantity: parseInt(item.quantity) || 1,
              dealTitle: item.dealTitle,
              discount: parseFloat(item.discount) || 0,
              originalPrice: parseFloat(item.originalPrice) || 0,
              businessId: item.businessId,
              name: item.name,
              optionId: item.optionId
            })),
            totalCost: this.totalCost,
            email: this.billingDetails.email,
            billingDetails: this.billingDetails
          }
        );

        if (response.data.order) {           
          // Navigate to confirmation page
          this.$router.push({
            name: 'OrderConfirmation',
            params: {
              userId: userId,
              orderId: response.data.order.orderId,
              orderData: JSON.stringify(response.data.order)
            }
          }).then(() => {
            this.checkoutCartItems.forEach((item) => {
              this.removeFromCheckoutCart(item.giftCardId, item.name);
            });
          });
        }
      } catch (error) {
        console.error('Error confirming order:', error);
        throw error;
      }
    },
      recalculateCosts() {
      const subtotal = parseFloat(this.subtotalCost) || 0;
      const discount = parseFloat(this.discountAmount) || 0;

      // Update total cost
      const total = Math.max(0, subtotal - discount);
      this.totalCost = Number.isFinite(total) ? total.toFixed(2) : '0.00';
    },
    updateCheckoutCart(items) {
      this.checkoutCartItems = items || [];
      localStorage.setItem('checkoutCartItems', JSON.stringify(this.checkoutCartItems));
    },
    removeFromCheckoutCart(giftCardId, name) {
      const user = JSON.parse(localStorage.getItem('user'));

      if (!user || !user.id) {
        // If user is not logged in, remove the item from localStorage directly
        this.checkoutCartItems = this.checkoutCartItems.filter(
          (item) => item.giftCardId !== giftCardId || item.name !== name
        );

        this.updateCheckoutCart(this.checkoutCartItems);

        const event = new CustomEvent('update-checkout-cart', { detail: this.checkoutCartItems });
        window.dispatchEvent(event);
        return;
      }

      // If the user is logged in, send a request to the API to remove the item
      const token = localStorage.getItem('token');
      axios
        .put(
          `${process.env.VUE_APP_API_URL}/api/shoppingCart/remove-from-cart/${user.id}`,
          { giftCardId, name },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          this.checkoutCartItems = this.checkoutCartItems.filter(
            (item) => item.giftCardId !== giftCardId || item.name !== name
          );

          this.updateCheckoutCart(this.checkoutCartItems);

          const event = new CustomEvent('update-checkout-cart', { detail: this.checkoutCartItems });
          window.dispatchEvent(event);
        })
        .catch((error) => {
          console.error('Error removing from checkout cart:', error);
        });
    },
    async fetchCountries() {
      try {
        const response = await axios.get('https://restcountries.com/v2/all');
        this.countries = response.data.map(country => ({
          code: country.alpha2Code,
          name: country.name
        }));
        this.billingDetails.country = 'US'; // Set default country to United States
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    },
    calculateDiscountedPrice(originalPrice, discount) {
      try {
        const price = parseFloat(originalPrice) || 0;
        const discountPercent = parseFloat(discount) || 0;
        const discountedPrice = price * (1 - discountPercent / 100);
        
        return Number.isFinite(discountedPrice) ? discountedPrice : 0;
      } catch (error) {
        console.error('Error calculating discounted price:', error);
        return 0;
      }
    },
    saveBillingDetails() {
      localStorage.setItem('billingDetails', JSON.stringify(this.billingDetails));
    },
    loadBillingDetails() {
      try {
        const savedDetails = localStorage.getItem('billingDetails');
        if (savedDetails) {
          this.billingDetails = JSON.parse(savedDetails);
        } else {
          console.warn('No billing details found in localStorage');
          this.billingDetails = {
            firstName: '',
            lastName: '',
            companyName: '',
            address: '',
            city: '',
            zipCode: '',
            country: 'US',
            email: '',
            phone: ''
          };
        }
      } catch (error) {
        console.error('Error parsing billing details:', error);
      }
    },
    async applyCoupon() {
      if (!this.couponCode) {
        this.couponMessage = 'Please enter a valid coupon code.';
        return;
      }

      this.isProcessing = true;
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/apply-coupon`, {
          code: this.couponCode,
          subtotal: parseFloat(this.subtotalCost) || 0
        });

        if (response.data.valid) {
          this.discountAmount = parseFloat(response.data.discountAmount) || 0;
          this.couponMessage = 'Coupon applied successfully!';
        } else {
          this.couponMessage = 'Invalid coupon code.';
          this.discountAmount = 0;
        }
      } catch (error) {
        this.couponMessage = 'Invalid coupon code.';
        this.discountAmount = 0;
      } finally {
        this.isProcessing = false;
      }
    },
    updateCartItems(event) {
      if (event && event.detail) {
        this.checkoutCartItems = event.detail;
      } else {
        this.checkoutCartItems = JSON.parse(localStorage.getItem('checkoutCartItems')) || [];
      }
    },
    
    async handlePayment() {
      if (!this.isFormValid) {
        this.transactionMessage = 'Please fill in all required billing details.';
        return;
      }

      if (!this.termsAgreed) {
        this.transactionMessage = 'Please agree to the terms and conditions before proceeding.';
        return;
      }

      this.isProcessing = true;

      try {
        this.validateCard();

        const userId = JSON.parse(localStorage.getItem('user'))?.id;
        if (!userId) {
          throw new Error('User not logged in');
        }

        const secureData = {
          authData: {
            clientKey: process.env.VUE_APP_AUTHORIZE_CLIENT_KEY,
            apiLoginID: process.env.VUE_APP_AUTHORIZE_API_LOGIN_ID
          },
          cardData: {
            cardNumber: this.cardDetails.cardNumber.replace(/\s/g, ''),
            month: this.cardDetails.expiryDate.split('/')[0],
            year: '20' + this.cardDetails.expiryDate.split('/')[1],
            cardCode: this.cardDetails.cvv
          }
        };

        const response = await new Promise((resolve, reject) => {
          this.acceptInstance.dispatchData(secureData, (response) => {
            if (response.messages.resultCode === 'Error') {
              reject(new Error(response.messages.message[0].text));
            } else {
              resolve(response);
            }
          });
        });

        const paymentResponse = await axios.post(`${process.env.VUE_APP_API_URL}/api/payment/process-payment`, {
          opaqueData: response.opaqueData,
          amount: this.totalCost,
          items: this.checkoutCartItems,
          billingDetails: this.billingDetails,
          userId // Include userId in the request body
        });

        if (paymentResponse.data.success) {
          await this.handleSuccessfulPayment(paymentResponse.data);
        } else {
          throw new Error(paymentResponse.data.message || 'Payment processing failed');
        }
      } catch (error) {
        console.error('Payment error:', error);
        this.transactionMessage = error.message || 'An error occurred during payment processing';
      } finally {
        this.isProcessing = false;
      }
    },
    async handleSuccessfulPayment(responseData) {
        // Clear cart
        this.checkoutCartItems.forEach((item) => {
          this.removeFromCheckoutCart(item.giftCardId, item.name);
        });

        // Navigate to confirmation page
        await this.$router.push({
          name: 'OrderConfirmation',
          params: {
            userId: responseData.order.userId,
            orderId: responseData.order.orderId,
            orderData: JSON.stringify(responseData.order)
          }
        });

      },
    async handleCheckout() {
      if (parseFloat(this.totalCost) <= 0) {
        this.transactionMessage = 'Invalid order total. Please check your cart.';
        return;
      }

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'begin_checkout',
        ecommerce: {
          currency: 'USD',
          value: parseFloat(this.totalCost),
          items: this.checkoutCartItems.map(item => ({
            item_name: item.name,
            item_id: item.giftCardId,
            price: parseFloat(item.originalPrice),
            quantity: parseInt(item.quantity)
          }))
        }
      });

      this.isProcessing = true;
      try {
        const userId = JSON.parse(localStorage.getItem('user'))?.id;
        if (!userId) {
          throw new Error('User not logged in');
        }

        const grandTotal = parseFloat(this.totalCost) || 0;
        if (grandTotal <= 0) {
          throw new Error('Invalid order total');
        }

        // Send taxAmount to create-payment-intent endpoint
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/payment/create-payment-intent`, {
          items: this.checkoutCartItems,
          currency: 'usd',
          taxAmount: parseFloat(this.taxCost), // Include the calculated tax amount
        });

        const result = await this.stripe.confirmCardPayment(response.data.clientSecret, {
          payment_method: {
            card: this.card,
            billing_details: {
              name: `${this.billingDetails.firstName} ${this.billingDetails.lastName}`,
              address: {
                line1: this.billingDetails.address,
                city: this.billingDetails.city,
                postal_code: this.billingDetails.zipCode,
                country: this.billingDetails.country,
              },
              email: this.billingDetails.email,
              phone: this.billingDetails.phone,
            },
          },
        });

        if (result.error) {
          this.transactionMessage = result.error.message;
          return;
        }

        const orderConfirmation = await axios.post(`${process.env.VUE_APP_API_URL}/api/payment/confirm-order`, {
          userId,
          items: this.checkoutCartItems.map(item => ({
            giftCardId: item.giftCardId,
            quantity: parseInt(item.quantity) || 1,
            dealTitle: item.dealTitle,
            discount: parseFloat(item.discount) || 0,
            originalPrice: parseFloat(item.originalPrice) || 0,
            businessId: item.businessId,
            name: item.name,
            optionId: item.optionId
          })),
          totalCost: grandTotal.toFixed(2),
          couponCode: this.couponCode,
          discountAmount: this.discountAmount,
          paymentIntentId: result.paymentIntent.id,
          email: this.billingDetails.email,
          taxAmount: this.taxCost, // Include the tax amount
        });
        this.$router.push({
          name: 'OrderConfirmation',
          params: {
            userId: userId,
            orderId: orderConfirmation.data.order.orderId,
            orderData: JSON.stringify(orderConfirmation.data.order)
          }
        }).then(() => {
          this.checkoutCartItems.forEach((item) => {
            this.removeFromCheckoutCart(item.giftCardId, item.name);
          });
          window.scrollTo(0, 0);
        });

        localStorage.setItem('checkoutCartItems', '[]');
      } catch (error) {
        this.transactionMessage = 'An error occurred during checkout. Please try again.';
        console.error('Error during checkout:', error);
      } finally {
        this.isProcessing = false;
      }
    },
    formatExpiryDate(event) {
      let input = event.target.value.replace(/\D/g, '').substring(0, 4);
      if (input.length > 2) {
        input = `${input.substring(0, 2)}/${input.substring(2, 4)}`;
      }
      this.cardDetails.expiryDate = input;
    },
    validateCVV(event) {
      let input = event.target.value.replace(/\D/g, '').substring(0, 5);
      this.cardDetails.cvv = input;
    },
    validateCardNumber(event) {
      let input = event.target.value.replace(/\D/g, '').substring(0, 17);
      this.cardDetails.cardNumber = input;
    },
    validateCard() {
      const cardNumber = this.cardDetails.cardNumber.replace(/\s/g, '');
      if (!/^\d{15,16}$/.test(cardNumber)) {
        throw new Error('Invalid card number');
      }

      const [month, year] = this.cardDetails.expiryDate.split('/');
      if (!month || !year || month < 1 || month > 12) {
        throw new Error('Invalid expiry date');
      }

      if (!/^\d{3,4}$/.test(this.cardDetails.cvv)) {
        throw new Error('Invalid CVV');
      }
    },
    viewFinePrint(item) {
      if (item.giftCardId) {
        // Navigate to the gift card page and store the reference to the router promise
        const navigationPromise = this.$router.push(`/gift-card/${item.giftCardId}`);
        
        // Handle the navigation completion
        navigationPromise.then(() => {
          // After navigation is complete, wait a short time for the page to render
          setTimeout(() => {
            // Try to find the fine print element by ID
            const finePrintElement = document.getElementById('fine-print');
            if (finePrintElement) {
              finePrintElement.scrollIntoView({ behavior: 'smooth' });
            } else {
              // If element not found by ID, try to find it by class
              const finePrintSection = document.querySelector('.fine-print-section');
              if (finePrintSection) {
                finePrintSection.scrollIntoView({ behavior: 'smooth' });
              } else {
                console.warn('Fine print section not found on gift card page');
              }
            }
          }, 1000); // Increased timeout to ensure page is fully rendered
        }).catch(error => {
          console.error('Navigation error:', error);
        });
      } else {
        console.error('No gift card ID found for this item');
      }
    },
  },

};
</script>

<style scoped>
.checkout-page {
  max-width: 1500px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  margin-bottom: 50px;
}

.card-fields-container {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #e1e7ef;
  border-radius: 8px;
}

.transaction-message {
  margin-top: 30px;
  color: green;
  font-size: 1.2em;
  font-weight: bold;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.transaction-message.fade-out {
  opacity: 0;
}

.payment-form {
  margin-top: 20px;
  position: relative;
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.payment-form .form-group {
  margin-bottom: 20px;
}

.payment-form .form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.payment-form .form-group input {
  width: 95%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.payment-form .form-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px; /* Add margin-bottom to create space between rows */
}

.payment-form .form-row .form-group {
  flex: 1;
  margin-right: 10px; /* Add margin-right to create space between fields */
}

.payment-form .form-row .form-group:last-child {
  margin-right: 0; /* Remove margin-right for the last form-group */
}

.payment-form .place-order-button {
  width: 100%;
  padding: 15px;
  background-color: #4C6B30;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.payment-form .place-order-button:hover {
  background-color: #3d5626;
}

.AcceptUI {
  width: 100%;
  padding: 15px;
  background-color: #4C6B30;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 4px;
  position: relative; /* Add this line */
}

.AcceptUI:hover {
  background-color: #3d5626;
}

#iframe_holder {
  width: 100%;
  height: 400px;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000; /* Add this line */
}

.card-field-row {
  display: flex;
  gap: 15px;
}

.card-field-row .card-field {
  flex: 1;
}

h1 {
  text-align: center;
  margin-bottom: 40px;
}

.checkout-container {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.order-summary, .billing-details {
  width: 48%;
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.billing-details h2, .order-summary h2 {
  margin-bottom: 20px;
  font-size: 1.5em;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input, .form-group select {
  width: 95%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-row {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}

.form-row .form-group {
  flex: 1;
}

.place-order-button {
  width: 100%;
  padding: 15px;
  background-color: #4C6B30;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 4px;
}

.order-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 15px 0;
  border-bottom: 1px solid #eee;
}

.item-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.fine-print-link {
  background: none;
  border: none;
  color: #2196F3;
  text-decoration: underline;
  padding: 0;
  font-size: 12px;
  cursor: pointer;
  margin-top: 5px;
  text-align: left;
}

.fine-print-link:hover {
  color: #0b7dda;
}

.subtotal-cost, .tax-cost, .total-cost {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding: 10px 0;
}

.total-cost {
  border-top: 2px solid #333;
  font-size: 1.2em;
  margin-top: 20px;
}

#card-element {
  border: 1px solid #ccc;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 4px;
}

.transaction-message {
  margin-top: 30px;
  color: green;
  font-size: 1.2em;
  font-weight: bold;
}

.processing-spinner {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.processing-spinner .spinner {
  width: 20px;
  height: 20px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-top: 3px solid #4C6B30;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-right: 10px;
}

.coupon-code {
  margin: 20px 0;
  padding: 20px;
  background: #f7f9fc;
  border: 1px solid #e1e7ef;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.coupon-code:focus-within {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.coupon-code label {
  display: block;
  font-weight: 600;
  margin-bottom: 8px;
  color: #374151;
}

.coupon-code-input-group {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.coupon-code input {
  flex: 1;
  padding: 12px 16px;
  border: 2px solid #e1e7ef;
  border-radius: 6px;
  font-size: 0.95em;
  transition: border-color 0.2s ease;
}

.coupon-code input:focus {
  outline: none;
  border-color: #4C6B30;
}

.coupon-code button {
  padding: 12px 24px;
  background-color: #4C6B30;
  color: white;
  border: none;
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 120px;
  margin-left: 10px;
}

.coupon-code button:hover:not(:disabled) {
  background-color: #3d5626;
  transform: translateY(-1px);
}

.coupon-code button:disabled {
  background-color: #a8b8a8;
  cursor: not-allowed;
}

.coupon-message {
  margin-top: 8px;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  gap: 8px;
}

.coupon-message.success {
  background-color: #ecfdf5;
  color: #065f46;
  border: 1px solid #a7f3d0;
}

.coupon-message.error {
  background-color: #fef2f2;
  color: #991b1b;
  border: 1px solid #fecaca;
}

.coupon-message::before {
  content: '';
  width: 16px;
  height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
}

.coupon-message.success::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23065f46'%3E%3Cpath fill-rule='evenodd' d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z' clip-rule='evenodd'/%3E%3C/svg%3E");
}

.coupon-message.error::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23991b1b'%3E%3Cpath fill-rule='evenodd' d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z' clip-rule='evenodd'/%3E%3C/svg%3E");
}

.discount-amount {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  color: #059669;
  font-weight: 600;
  border-top: 1px dashed #e5e7eb;
  margin-top: 10px;
}

.discount-amount span:first-child::before {
  content: '🏷️';
  margin-right: 8px;
}

.terms-checkbox {
  margin: 20px 0;
  font-size: 14px;
}


.terms-checkbox a {
  color: #4C6B30;
  text-decoration: none;
}

.terms-checkbox a:hover {
  text-decoration: underline;
}

.terms-agreement {
  display: flex;
  align-items: flex-start;
  margin: 20px 0;
  padding: 15px;
  background: #f7f9fc;
  border: 1px solid #e1e7ef;
  border-radius: 8px;
  text-align: left;
}

.terms-agreement input {
  margin-right: 10px;
  margin-top: 3px;
}

.terms-agreement label {
  font-size: 14px;
  line-height: 1.5;
  color: #333;
}

.terms-agreement a {
  color: #4C6B30;
  text-decoration: underline;
  transition: color 0.2s ease;
}

.terms-agreement a:hover {
  color: #6E9F49;
}

.payment-section {
  margin-top: 25px;
  animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .checkout-container {
    flex-direction: column;
    align-items: center;
  }

  .order-summary, .billing-details {
    width: 100%;
    max-width: 600px;
  }

  .form-row {
    flex-direction: column;
    gap: 20px;
  }
}

@media (max-width: 768px) {
  .checkout-page {
    max-width: 100%;
    padding: 0 20px;
  }

  .checkout-container {
    padding: 0;
    flex-direction: column;
    align-items: center;
  }

  .form-group input, .form-group select, #card-element {
    padding: 8px;
  }

  .place-order-button {
    padding: 10px;
    font-size: 16px;
  }

  .coupon-code button {
    width: 100%;
  }

  #iframe_holder {
    height: auto;
  }

  .order-summary, .billing-details {
    width: 100%;
    max-width: 600px;
  }

  .form-row {
    flex-direction: column;
    gap: 20px;
  }
  
  .order-item {
    flex-direction: column;
  }
  
  .item-price {
    margin-top: 10px;
    align-self: flex-end;
  }
}
</style>
