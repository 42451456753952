<template>
  <div class="container">
    <MetaManager
      title="Login "
      description="Welcome to our login page, where security meets simplicity. Log in easily and securely to manage your account with confidence and convenience."
    />
    <div class="form-container">
      <h2 class="title">Login to your account</h2>
      <form @submit.prevent="login">
        <div class="input-group">
          <label for="email">E-mail Address</label>
          <input type="email" id="email" v-model="email" required />
        </div>
        <div class="input-group">
          <label for="password">Password</label>
          <div class="password-field">
            <input :type="showPassword ? 'text' : 'password'" id="password" v-model="password" required />
            <button type="button" class="password-toggle" @click="togglePasswordVisibility">
              <i :class="showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"></i>
            </button>
          </div>
        </div>
        <button type="submit" class="login-button">Login</button>
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
      </form>
      <div class="or-register">or login with</div>
      <div class="social-login">
        <div id="g_id_onload"
             :data-client_id="googleClientId"
             data-context="signin"
             data-ux_mode="popup"
             data-callback="handleCredentialResponse"
             data-auto_prompt="false">
        </div>

        <div class="g_id_signin"
             data-type="standard"
             data-shape="rectangular"
             data-theme="outline"
             data-text="signin_with"
             data-size="large"
             data-logo_alignment="left">
        </div>
      </div>
      <div class="forgot-password">
        <button @click="redirectToForgotPassword" class="forgot-password-button">Forgot Password?</button>
      </div>
    </div>
    <div class="register-redirect">
      <button @click="redirectToRegister" class="register-button">Don't have an account? Register</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { setUser } from '../../services/auth';
import MetaManager from '../../components/MetaManager.vue';

export default {
  components: {
    MetaManager
  },    
  data() {
    return {
      email: '',
      password: '',
      errorMessage: '', 
      googleClientId: '162400744570-8mffcm4pl6acib2jh0iqm94tp6l5nko4.apps.googleusercontent.com',
      showPassword: false,
    };
  },
  mounted() {
    this.loadGoogleIdentityServices();
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    async login() {
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/login`, {
          email: this.email,
          password: this.password,
        });

        setUser(response.data.user);
        localStorage.setItem('token', response.data.token);

        // Fetch local cart items before overwriting
        const localCartItems = JSON.parse(localStorage.getItem('checkoutCartItems')) || [];
        const serverCartItems = response.data.user.checkoutCartItems || [];

        // Merge local and server cart items based on cartItemId
        const mergedCart = [...serverCartItems];

        localCartItems.forEach(localItem => {
          const isDuplicate = mergedCart.some(serverItem =>
            serverItem.cartItemId === localItem.cartItemId
          );
          if (!isDuplicate) {
            mergedCart.push(localItem);
          }
        });

        // Update localStorage with the merged cart
        localStorage.setItem('checkoutCartItems', JSON.stringify(mergedCart));
        localStorage.setItem('shoppingBagItems', JSON.stringify(response.data.user.shoppingBagItems));
        localStorage.setItem('billingDetails', JSON.stringify(response.data.user.billingDetails));

        // ✅ Push each merged cart item to the server individually
        if (mergedCart.length > 0) {
          const userId = response.data.user.id;
          const token = localStorage.getItem('token');

          try {
            // Add each local item to the server cart
            for (const item of localCartItems) {
              // Skip if this item is already in serverCartItems
              if (!serverCartItems.some(serverItem => serverItem.cartItemId === item.cartItemId)) {
                await axios.post(
                  `${process.env.VUE_APP_API_URL}/api/shoppingCart/saveCart/${userId}`, 
                  item, // Send each item directly without wrapping in an object
                  {
                    headers: { 'Authorization': `Bearer ${token}` }
                  }
                );
              }
            }

            console.log('Merged cart saved to server.');
          } catch (err) {
            console.error("Error pushing merged cart to server:", err);
          }
        }

        // Redirect logic
        if (response.data.user.isBusiness || response.data.user.isAdmin) {
          this.$router.push('/dashboard');
        } else {
          const redirectTo = localStorage.getItem('redirectAfterLogin');
          if (redirectTo) {
            localStorage.removeItem('redirectAfterLogin');
            this.$router.push(redirectTo);
          } else {
            window.location.href = `/account/${response.data.user.id}/orders`;
          }
        }
      } catch (error) {
        console.error('Error logging in', error);
        this.errorMessage = error.response?.data?.error || 'An error occurred during login.';
      }
    },
    loadGoogleIdentityServices() {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);

      // Define the global callback function
      window.handleCredentialResponse = this.handleGoogleSignIn;
    },
    async handleGoogleSignIn(response) {
      if (response.credential) {
        try {
          const result = await axios.post(`${process.env.VUE_APP_API_URL}/api/login/google`, {
            idToken: response.credential,
          });
          this.handleSuccessfulLogin(result.data);
        } catch (error) {
          console.error('Error during Google Sign-In:', error);
          this.errorMessage = error.response.data.error;
        }
      }
    },
    handleSuccessfulLogin(data) {
      setUser(data.user);
      localStorage.setItem('token', data.token);
      localStorage.setItem('shoppingBagItems', JSON.stringify(data.user.shoppingBagItems));
      localStorage.setItem('checkoutCartItems', JSON.stringify(data.user.checkoutCartItems));
      localStorage.setItem('billingDetails', JSON.stringify(data.user.billingDetails));

      // Check if user is business or admin
      if (data.user.isBusiness || data.user.isAdmin) {
        this.$router.push('/dashboard');
      } else {
        const redirectTo = localStorage.getItem('redirectAfterLogin');
        if (redirectTo) {
          localStorage.removeItem('redirectAfterLogin');
          this.$router.push(redirectTo);
        } else {
          window.location.href = `/account/${data.user.id}/orders`;
        }
      }
    },
    async updateCart(items, userId) {
      const token = localStorage.getItem('token');
      
      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/api/shoppingCart/saveCart/${userId}`, { items }, {
          headers: { 'Authorization': `Bearer ${token}` }
        });

        localStorage.setItem('checkoutCartItems', JSON.stringify(items));
        window.dispatchEvent(new CustomEvent('update-checkout-cart', { detail: items }));
      } catch (error) {
        console.error("Error updating cart:", error);
      }
    },
    redirectToRegister() {
      localStorage.setItem('redirectAfterLogin', this.$route.fullPath);
      this.$router.push('/register');
    },
    redirectToForgotPassword() {
      this.$router.push('/forgot-password');
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
  background-color: #f5f5f5;
}

.form-container {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  margin: 1rem 0;
}

.title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  text-align: center;
}

.input-group {
  margin-bottom: 1rem;
}

.input-group label {
  display: block;
  margin-bottom: 0.5rem;
}

.input-group input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.password-field {
  position: relative;
  display: flex;
  align-items: center;
}

.password-field input {
  width: 100%;
  padding-right: 40px; /* Make space for the eye icon */
}

.password-toggle {
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
  padding: 0;
  font-size: 1rem;
}

.password-toggle:hover {
  color: #4C6B30;
}

.input-group input:focus {
  border-color: #6E9F49;
  outline: none;
}

.login-button {
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  background-color: #4C6B30;
  color: white;
  font-size: 1rem;
  cursor: pointer;
}

.login-button:hover {
  background-color: #6E9F49;
}

.or-register {
  text-align: center;
  font-size: 1rem;
  margin: 1rem 0;
  color: #666;
}

.forgot-password {
  margin-top: 1rem;
  text-align: center;
}

.forgot-password-button {
  background: none;
  border: none;
  color: #4C6B30;
  cursor: pointer;
  font-size: 1rem;
}

.forgot-password-button:hover {
  text-decoration: underline;
}

.register-redirect {
  margin-top: 1rem;
  text-align: center;
}

.register-button {
  background: none;
  border: none;
  color: #4C6B30;
  cursor: pointer;
  font-size: 1rem;
}

.register-button:hover {
  text-decoration: underline;
}

.error-message {
  color: red;
  font-size: 0.9rem;
  text-align: center;
  margin-top: 0.5rem;
}

.social-login {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

@media (max-width: 1600px){
  .container{
    padding-top: 20px;
  }
}

@media (max-width: 1024px){
  .container{
    margin-top: 50px;
    margin-bottom: 0px;
    min-height: 40vh;
  }
}

@media (max-width: 768px) {

  .form-container {
    padding: 1.5rem;
  }

  .title {
    font-size: 1.3rem;
  }

  .input-group input {
    font-size: 0.9rem;
  }

  .login-button {
    padding: 0.5rem;
    font-size: 0.9rem;
  }

  .forgot-password-button,
  .register-button {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 0.5rem;
    margin-bottom: 50px;
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .form-container {
    max-width: 320px;
  }

  .title {
    font-size: 1.1rem;
  }

  .input-group input {
    font-size: 0.8rem;
  }

  .login-button {
    padding: 0.5rem;
    font-size: 0.8rem;
  }

  .forgot-password-button,
  .register-button {
    font-size: 0.8rem;
  }
}
</style>
